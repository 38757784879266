import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Wholesale = () => (
  <Layout>
    <SEO title="Wholesale" />
    <WholesaleWrapper>
      <div className="container">
        <h1>Coming Soon!</h1>
        <h3>
          Apologies for the delay, we are currently working on building this
          page.
        </h3>
        <h3>
          Please email us at <span>info@belgianboys.com</span> for wholesale
          orders.
        </h3>
      </div>
    </WholesaleWrapper>
  </Layout>
)

export default Wholesale

const WholesaleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: var(--darkBlue);
    text-transform: uppercase;
    margin: 50px 0;
  }
  .container {
    text-align: center;
    span {
      color: var(--darkBlue);
    }
    h3 {
      color: var(--pink);
    }
  }

  min-height: 60vh;
  background-color: var(--beige);
`
